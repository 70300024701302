
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        



















































































































































































































.hardware-specs,
[class*='hardware-specs--'] {
  z-index: -1;
  overflow: auto;
  width: 100vw;
  max-width: 110rem;
  height: 100vh;
  background: $white;
}

.hardware-specs-inner {
  position: relative;
  // overflow: hidden;
  padding: 5rem 2rem;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s;

  .is-ready & {
    visibility: visible;
    opacity: 1;
  }

  @include mq(m) {
    padding: 5rem 10rem;
  }
}

.hardware-specs__speedline {
  position: absolute;
  // right: -4%;
  right: 0;
  bottom: 0;
  overflow: hidden;
  width: 50%;
  max-height: 27rem;
  // transform: translateY(100%);

  ::v-deep {
    path {
      transform: translateY(80%);
    }
  }

  .gray & {
    fill: $c-gray;
  }

  .blue & {
    fill: $c-light-blue;
  }

  .paleblue & {
    fill: $c-blue-pale;
  }

  .cyan & {
    fill: $c-cyan-contrast;
  }

  .orange & {
    fill: $c-orange;
  }

  .burgundy & {
    fill: $c-burgundy;
  }

  .midnightblue & {
    fill: $c-blue-dark;
  }

  .pink & {
    fill: $c-pink-light;
  }

  .lightpink & {
    fill: $c-pink-light;
  }

  .purple & {
    fill: $c-purple;
  }

  .gift-color-a & {
    fill: $c-gift-color-a;
  }

  .gift-color-b & {
    fill: $c-gift-color-b;
  }

  .gift-color-c & {
    fill: $c-gift-color-c;
  }
}

.hardware-specs__header {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // overflow: hidden;
  max-height: 40rem;
  padding: 2rem 5rem;
  color: $c-light;
  background-color: $c-promo;

  @include mq(m) {
    padding: 5rem 10rem;
  }
}

.hardware-specs__header__title {
  @extend %fw-medium;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  flex-basis: 50%;
  align-self: center;
  max-width: 100%;
  font-family: $ff-alt;
  font-size: 1.5rem;

  .label--pink {
    margin-bottom: $spacing;
    font-size: 1.3rem;
    font-weight: 900;
  }

  ::v-deep {
    img {
      max-width: 10rem;
      max-height: 5rem;
      margin-bottom: 2rem;
    }

    .hardware-specs__subtitle {
      margin-bottom: 1rem;
      font-size: 1.4rem;
      font-weight: 700;
      white-space: nowrap;

      @include mq('l') {
        font-size: 2rem;
      }
    }

    strong {
      margin-bottom: 2rem;
    }
  }
}

.hardware-specs__header__picture {
  position: relative;
  z-index: 1;
  flex-basis: 50%;
  height: 25rem;
  margin: 0;

  ::v-deep img {
    @include image-fit(contain, right bottom);
  }

  @include mq(l) {
    height: 50rem;
  }
}

.hardware-specs__header__background-outer {
  @include get-all-space;

  z-index: -1;
  overflow: hidden;
}

.hardware-specs__cta {
  margin-top: $spacing;

  @include mq($until: 'm') {
    .hardware-specs-inner & {
      margin-bottom: $spacing * 5;
    }
  }
}

.hardware-specs__wysiwyg {
  @include mq(l) {
    margin-top: $spacing * 2;
  }

  ::v-deep .voo-link.h5 {
    font-size: 1.6rem;
  }
}

.hardware-specs__wysiwyg.wysiwyg p {
  margin: 1rem 0 4rem;
}
